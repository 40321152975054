import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import Alert from "@mui/material/Alert";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import useInsertCategory from "../graphql/useInsertCategory";
import TitleWrapper from "./Dashboard/TitleWrapper";

const formTheme = createTheme({
  typography: {
    fontFamily: ["Roboto", '"Segoe UI"', '"Helvetica Neue"'].join(","),
  },
});

function NewCategoryForm() {
  const [insertCategory, { data, loading, error }] = useInsertCategory();
  const [successAlert, setSuccessAlert] = useState(false);

  useEffect(() => {
    if (data) {
      setSuccessAlert(true);
    }
  }, [data]);

  if (loading) {
    return "Sending new category...";
  }

  return (
    <Formik
      initialValues={{
        name: null,
      }}
      onSubmit={async values => {
        insertCategory({
          variables: {
            name: values.name,
          },
        });
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
      })}
    >
      {props => {
        const { isSubmitting, handleBlur, handleSubmit, setFieldValue } = props;

        return (
          <TitleWrapper title="New Category">
            <ThemeProvider theme={formTheme}>
              {successAlert && (
                <Alert severity="success">Category successfully created.</Alert>
              )}
              {error && (
                <Alert severity="error">
                  {error.graphQLErrors.map(({ message }, i) => (
                    <span key={i}>{message}</span>
                  ))}
                </Alert>
              )}
              <form onSubmit={handleSubmit} className="newCategoryForm">
                <Container maxWidth="md">
                  <Grid container spacing={3} marginBottom={2}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="h3" color="#1976d2">
                        New Category
                      </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <TextField
                        id="name"
                        name="name"
                        label="Category Name"
                        variant="outlined"
                        type="text"
                        onChange={event => {
                          if (event) {
                            setFieldValue(
                              "name",
                              event.target.value.toUpperCase()
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        // disabled={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Container>

                <Container maxWidth="md">
                  <Grid container spacing={12} style={{ textAlign: "center" }}>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </form>
            </ThemeProvider>
          </TitleWrapper>
        );
      }}
    </Formik>
  );
}

export default NewCategoryForm;
