import React, { useState } from "react";
import TitleWrapper from "./Dashboard/TitleWrapper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { StoreLocationCombo } from "./Dashboard/NewAssetForm/NewAssetLocation";
import TagsHistoryTable from "./Dashboard/TagsHistoryTable";

const TagsHistory = () => {
  let startDate = new Date(new Date().setHours(0, 0, 0));
  startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());
  let endDate = new Date(new Date().setHours(23, 59, 59));
  endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());

  const [variables, setVariables] = useState(null);

  return (
    <TitleWrapper title="Tags History">
      <Formik
        initialValues={{
          rfidCode: "",
          facilityId: "",
          startDate: startDate.toISOString().slice(0, 16),
          endDate: endDate.toISOString().slice(0, 16),
          found: "",
        }}
        onSubmit={async values => {
          const newVariebles = { ...values };

          Object.keys(newVariebles).forEach(name => {
            if (
              !newVariebles[name] &&
              typeof newVariebles[name] !== "boolean"
            ) {
              delete newVariebles[name];
            }
          });

          setVariables({
            ...newVariebles,
            startDate: new Date(values.startDate),
            endDate: new Date(values.endDate),
          });
        }}
        validationSchema={Yup.object().shape({
          rfidCode: Yup.string(),
          facilityId: Yup.string(),
          startDate: Yup.date(),
          endDate: Yup.date(),
          found: Yup.boolean().nullable(true),
        })}
      >
        {({
          isSubmitting,
          handleSubmit,
          setFieldValue,
          values,
          handleBlur,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 16 }}
                >
                  <Grid item xs={4} sm={8} md={3}>
                    <TextField
                      id="rfidCode"
                      name="rfidCode"
                      label="RFID code"
                      variant="outlined"
                      type="text"
                      fullWidth
                      onChange={event => {
                        if (event) {
                          setFieldValue("rfidCode", event.target.value);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} sm={8} md={3}>
                    <StoreLocationCombo
                      id="facilityId"
                      name="facilityId"
                      value={values.facilityId}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={4} sm={8} md={3}>
                    <TextField
                      id="startDate"
                      name="startDate"
                      value={values.startDate}
                      variant="outlined"
                      type="datetime-local"
                      label="Start Date"
                      fullWidth
                      onChange={event => {
                        if (event) {
                          setFieldValue("startDate", event.target.value);
                        }
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={4} sm={8} md={3}>
                    <TextField
                      id="endDate"
                      name="endDate"
                      value={values.endDate}
                      variant="outlined"
                      type="datetime-local"
                      label="End Date"
                      fullWidth
                      onChange={event => {
                        if (event) {
                          setFieldValue("endDate", event.target.value);
                        }
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={4} sm={8} md={3}>
                    <FormControl fullWidth>
                      <InputLabel id="found-label">Found</InputLabel>
                      <Select
                        labelId="found-label"
                        id="found"
                        value={values.found}
                        label="Found"
                        onChange={event => {
                          setFieldValue("found", event.target.value);
                        }}
                      >
                        <MenuItem value={""}>All</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <div style={{ textAlign: "center", margin: "2rem 0" }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Search
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
      {variables && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: "60vh",
                width: "80vw",
              }}
            >
              <TagsHistoryTable variables={variables} />
            </Paper>
          </Grid>
        </Grid>
      )}
    </TitleWrapper>
  );
};

export default TagsHistory;
