import { gql, useLazyQuery } from "@apollo/client";

const useGetEmptyBins = variables => {
  const [getEmptyBins, { data, loading, error }] = useLazyQuery(
    gql`
      query ($facilityId: String) {
        getEmptyBins(input: $facilityId) {
          emptyBins
          takenBins
        }
      }
    `,
    { variables: variables, fetchPolicy: "network-only" }
  );
  return { getEmptyBins, data, loading, error };
};
export default useGetEmptyBins;
