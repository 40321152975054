import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import Title from "./Title";
import useAssetsByFacilityStatus from "../../graphql/useAssetsByFacilityStatus";
import Loading from "../Loading";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";

export default function AssetsByFacilityStatus() {
  const [assetData, setAssetData] = useState([]);
  const { data, loading, error } = useAssetsByFacilityStatus();
  const history = useHistory();

  useEffect(() => {
    if (data) {
      const row = {
        id: null,
        facility: null,
        stored: null,
        transfer: null,
        received: null,
        total: null,
      };

      let rows = [];

      data["assetsByFacilityStatus"].forEach(e => {
        let thisRow = Object.create(row);

        thisRow.facility = e.facility;
        e.counts.forEach(ee => {
          if (ee.status === "STORED") thisRow.stored = ee.count;
          else if (ee.status === "TRANSFER") thisRow.transfer = ee.count;
          else if (ee.status === "RECEIVED") thisRow.received = ee.count;
        });

        rows.push(thisRow);
      });

      rows.forEach((thisRow, index) => {
        thisRow.id = index;
        thisRow.total = thisRow.stored + thisRow.transfer + thisRow.received;
      });

      setAssetData(rows);
    }
  }, [data]);

  const [sortModel, setSortModel] = React.useState([
    {
      field: "total",
      sort: "desc",
    },
  ]);

  if (loading) return <Loading />;
  if (error) return <h2>Error loading Assets by Facility and Status!</h2>;

  return (
    <>
      <Title>Assets by Facility and Status</Title>
      <div style={{ display: "flex", height: "100vh", width: "100%" }}>
        <DataGrid
          rows={assetData}
          columns={columns}
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
          density="compact"
          onCellClick={e => {
            if (e.field !== "facility") {
              history.push("/search-asset", {
                assetsByFacilityStatusSearch: {
                  status: e.field.toUpperCase(),
                  facility: e.row.facility,
                },
              });
            }
          }}
        />
      </div>
    </>
  );
}

const columns = [
  {
    field: "facility",
    headerName: "Facility",
    minWidth: 140,
    flex: 1,
  },
  {
    field: "stored",
    headerName: "Stored",
    type: "number",
    width: 140,
    renderCell: params =>
      params.value ? <Button>{params.value}</Button> : null,
  },
  {
    field: "transfer",
    headerName: "Transfer",
    type: "number",
    width: 140,
    renderCell: params =>
      params.value ? <Button>{params.value}</Button> : null,
  },
  {
    field: "received",
    headerName: "Received",
    type: "number",
    width: 140,
    renderCell: params =>
      params.value ? <Button>{params.value}</Button> : null,
  },
  {
    field: "total",
    headerName: "Total",
    type: "number",
    width: 140,
    renderCell: params =>
      params.value ? <Button>{params.value}</Button> : null,
  },
];
