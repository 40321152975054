import { useLazyQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GET_PRODUCTS_CUSTOM_CODES } from "../NewAssetForm/queries";

const ItemInput = ({
  onChange,
  productQuantitiesbyCategorySearch,
  value,
  handleSubmit,
}) => {
  const [products, setProducts] = useState([]);
  const [defaultProductAdded, setDefaultProductAdded] = useState(false);
  const [search, { data }] = useLazyQuery(GET_PRODUCTS_CUSTOM_CODES);

  useEffect(() => {
    if (data) {
      setProducts(data["productSearch"]);
      if (
        productQuantitiesbyCategorySearch &&
        productQuantitiesbyCategorySearch.product !== "All Products" &&
        !defaultProductAdded
      ) {
        const productObject = data["productSearch"].find(
          item => item.customCode === productQuantitiesbyCategorySearch.product
        );
        if (productObject) {
          onChange("productIdArr", [productObject]);
          handleSubmit();
        }
        setDefaultProductAdded(true);
      }
    }
  }, [
    data,
    productQuantitiesbyCategorySearch,
    onChange,
    value,
    defaultProductAdded,
    handleSubmit,
  ]);

  useEffect(() => {
    if (
      productQuantitiesbyCategorySearch &&
      productQuantitiesbyCategorySearch.product !== "All Products"
    ) {
      search({
        variables: { input: `${productQuantitiesbyCategorySearch.product}%` },
      });
    }
  }, [productQuantitiesbyCategorySearch, search]);

  return (
    <Autocomplete
      multiple
      options={products}
      onChange={(event, newValue) => {
        onChange("productIdArr", newValue);
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue) {
          search({ variables: { input: `${newInputValue}` } });
        }
      }}
      value={value}
      getOptionLabel={option => `${option.customCode} | ${option.name}`}
      filterSelectedOptions
      renderInput={params => <TextField {...params} label="Items" />}
    />
  );
};

export default ItemInput;
