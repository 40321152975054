import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";

const StatusInput = ({
  onChange,
  value,
  assetsByFacilityStatusSearch,
  handleSubmit,
}) => {
  useEffect(() => {
    if (
      assetsByFacilityStatusSearch &&
      assetsByFacilityStatusSearch.status !== "TOTAL"
    ) {
      onChange("statusArr", [assetsByFacilityStatusSearch.status]);
      handleSubmit();
    }
  }, [assetsByFacilityStatusSearch, onChange, handleSubmit]);

  return (
    <Autocomplete
      multiple
      options={["STORED", "RECEIVED", "TRANSFER", "RETIRED"]}
      onChange={(event, newValue) => {
        onChange("statusArr", newValue);
      }}
      getOptionLabel={option => option.toLocaleLowerCase()}
      value={value}
      filterSelectedOptions
      renderInput={params => <TextField {...params} label="Status" />}
    />
  );
};

export default StatusInput;
