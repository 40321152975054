import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { GET_PRODUCTS_CUSTOM_CODES } from "./queries";

export function AssetProduct(props) {
  const [products, setProducts] = useState([]);
  const [search, { data }] = useLazyQuery(GET_PRODUCTS_CUSTOM_CODES);

  useEffect(() => {
    if (data) {
      setProducts(data["productSearch"]);
    }
  }, [data]);

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        if (newValue) {
          props.onChange(props.name, newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue) {
          search({ variables: { input: `${newInputValue}` } });
        }
      }}
      options={products}
      getOptionLabel={option => `${option.customCode} | ${option.name}`}
      value={props.value}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      renderInput={params => (
        <TextField
          {...params}
          name={props.name}
          value={props.value}
          label={props.label}
          onReset={props.onReset}
          variant="outlined"
          autoFocus
          required
        />
      )}
    />
  );
}
