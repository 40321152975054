import { TextField } from "@mui/material";
import React, { useEffect } from "react";

const TagCodeTextField = ({
  onChange,
  handleSubmit,
  assetsTransferTimesSearch,
  ...props
}) => {
  useEffect(() => {
    if (assetsTransferTimesSearch && assetsTransferTimesSearch.tagCode) {
      onChange(props.name, assetsTransferTimesSearch.tagCode);
      handleSubmit();
    }
  }, [assetsTransferTimesSearch, onChange, props.name, handleSubmit]);

  return (
    <TextField
      {...props}
      onChange={event => {
        if (event) {
          onChange(props.name, event.target.value.toUpperCase());
        }
      }}
    />
  );
};

export default TagCodeTextField;
