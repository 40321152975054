import { gql, useLazyQuery } from "@apollo/client";

const useProductsByIds = () => {
  const [getProductsByIds, { data, loading, error }] = useLazyQuery(
    gql`
      query productsByIds($ids: [ObjectId]) {
        products(query: { _id_in: $ids }) {
          _id
          name
          customCode
        }
      }
    `
  );
  return [getProductsByIds, { error, loading, data }];
};

export default useProductsByIds;
