import { gql, useQuery } from "@apollo/client";

const useRfidHistory = variables => {
  const { data, loading, error } = useQuery(
    gql`
      query (
        $rfidCode: String
        $facilityId: ObjectId
        $startDate: DateTime
        $endDate: DateTime
        $found: Boolean
      ) {
        rfidHistories(
          query: {
            rfidCode: $rfidCode
            facilityId: $facilityId
            AND: [{ date_gte: $startDate }, { date_lte: $endDate }]
            found: $found
          }
        ) {
          _id
          rfidCode
          facilityId
          date
          found
        }
      }
    `,
    { variables: variables }
  );
  return { data, loading, error };
};
export default useRfidHistory;
