import { useLazyQuery } from "@apollo/client";
import { GET_ASSETS_DETAILS } from "../components/Dashboard/NewAssetForm/queries";

const useAssetDetails = variables => {
  const [getAssetDetails, { data, loading, error }] = useLazyQuery(
    GET_ASSETS_DETAILS,
    { variables: variables }
  );

  return { getAssetDetails, data, loading, error };
};

export default useAssetDetails;
