import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import AssetTable from "./AssetTable";
import FacilityInput from "./Dashboard/SearchInputs/FacilityInput";
import { Formik } from "formik";
import * as Yup from "yup";
import StatusInput from "./Dashboard/SearchInputs/StatusInput";
import { Button, CircularProgress } from "@mui/material";
import ItemInput from "./Dashboard/SearchInputs/ItemInput";
import CategoryInput from "./Dashboard/SearchInputs/CategoryInput";
import BinsInput from "./Dashboard/SearchInputs/BinsInput";
import TitleWrapper from "./Dashboard/TitleWrapper";
import TagCodeTextField from "./Dashboard/SearchInputs/TagCodeTextField";
import XLSX from "xlsx";
import { useApolloClient } from "@apollo/client";
import { GET_ASSETS_DETAILS } from "./Dashboard/NewAssetForm/queries";

export default function AssetsTableFilter(props) {
  const [variables, setVariables] = useState(null);
  const [facilityList, setFacilityList] = useState([]);
  const [currentFacilityBins, setCurrentFacilityBins] = useState([]);
  const [
    productQuantitiesbyCategorySearch,
    setProductQuantitiesbyCategorySearch,
  ] = useState(null);
  const [assetsByFacilityStatusSearch, setAssetsByFacilityStatusSearch] =
    useState(null);
  const [assetsTransferTimesSearch, setAssetsTransferTimesSearch] =
    useState(null);
  const [searchResultData, setSearchResultData] = useState();
  const client = useApolloClient();
  const [loadingExcel, setLoadingExcel] = useState(false);

  useEffect(() => {
    if (props?.location?.state?.productQuantitiesbyCategorySearch) {
      setProductQuantitiesbyCategorySearch(
        props?.location?.state?.productQuantitiesbyCategorySearch
      );
    }
    if (props?.location?.state?.assetsByFacilityStatusSearch) {
      setAssetsByFacilityStatusSearch(
        props?.location?.state?.assetsByFacilityStatusSearch
      );
    }
    if (props?.location?.state?.assetsTransferTimesSearch) {
      setAssetsTransferTimesSearch(
        props?.location?.state?.assetsTransferTimesSearch
      );
    }
  }, [props]);

  const downloadExcel = async () => {
    setLoadingExcel(true);
    let newTablaData = [];

    for (const asset of searchResultData.assetsSearch) {
      if (asset.items.length) {
        const { data } = await client.query({
          query: GET_ASSETS_DETAILS,
          variables: {
            productIdArr: asset.items.map(item => item.itemId),
          },
        });

        data.products.forEach(itemRes => {
          const currentProduct = asset.items.find(
            item => item.itemId === itemRes._id
          );

          if (currentProduct) {
            newTablaData.push({
              "Tag Code": asset.customCode,
              Status: asset.status,
              "Pack Date": asset.eventDate,
              Facility: asset.facilityId.name,
              Bin: asset.binCode,
              "Product Code": itemRes.customCode,
              "Product Name": itemRes.name,
              Category: itemRes.categoryId.name,
              Subcategory: itemRes.subcategory,
              "Item Notes": currentProduct.notes,
              Quantity: currentProduct.quantity,
              Priced: currentProduct.isPriced ? "Yes" : "No",
            });
          }
        });
      } else {
        newTablaData.push({
          "Tag Code": asset.customCode,
          Status: asset.status,
          "Pack Date": asset.eventDate,
          Facility: asset.facilityId.name,
          Bin: asset.binCode,
          "Product Code": "",
          "Product Name": "",
          Category: "",
          Subcategory: "",
          "Item Notes": "",
          Quantity: "",
          Priced: "",
        });
      }
    }

    let workSheet = XLSX.utils.json_to_sheet(newTablaData);
    workSheet["!cols"] = Object.keys(newTablaData[0]).map(item => {
      if (
        item === "Pack Date" ||
        item === "Product Name" ||
        item === "Item Notes"
      ) {
        return {
          wch: 20,
        };
      } else {
        return {
          wch: 10,
        };
      }
    });

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Assets");
    //Buffer
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(
      workBook,
      `search-asset-export-${new Date().toLocaleString()}.xlsx`
    );

    setLoadingExcel(false);
  };

  return (
    <TitleWrapper title="Search Asset">
      <Formik
        initialValues={{
          assetIdArr: [],
          statusArr: [],
          customCodeArr: "",
          facilityIdArr: [],
          productIdArr: [],
          categoryIdArr: [],
          binCodeArr: [],
        }}
        onSubmit={async values => {
          let newVariebles = { ...values };
          newVariebles.categoryIdArr = values.categoryIdArr.map(
            item => item._id
          );
          newVariebles.productIdArr = values.productIdArr.map(item => item._id);
          newVariebles.facilityIdArr = values.facilityIdArr.map(
            item => item._id
          );
          newVariebles.assetIdArr = values.assetIdArr.map(item => item._id);

          Object.keys(newVariebles).forEach(name => {
            if (!newVariebles[name].length) {
              delete newVariebles[name];
            }
          });

          newVariebles.offset = 0;
          newVariebles.limit = 100;

          setVariables(newVariebles);
        }}
        validationSchema={Yup.object().shape({
          assetIdArr: Yup.array(),
          statusArr: Yup.array(),
          customCodeArr: Yup.string(),
          facilityIdArr: Yup.array(),
          productIdArr: Yup.array(),
          categoryIdArr: Yup.array(),
          binCodeArr: Yup.array(),
        })}
      >
        {({ isSubmitting, handleSubmit, setFieldValue, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <TagCodeTextField
                      name="customCodeArr"
                      label="Asset Tag Code"
                      variant="outlined"
                      type="text"
                      fullWidth
                      onChange={setFieldValue}
                      value={values.customCodeArr}
                      assetsTransferTimesSearch={assetsTransferTimesSearch}
                      handleSubmit={handleSubmit}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <FacilityInput
                      onChange={setFieldValue}
                      value={values.facilityIdArr}
                      setFacilityList={setFacilityList}
                      setCurrentFacilityBins={setCurrentFacilityBins}
                      assetsByFacilityStatusSearch={
                        assetsByFacilityStatusSearch
                      }
                      assetsTransferTimesSearch={assetsTransferTimesSearch}
                      handleSubmit={handleSubmit}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <BinsInput
                      onChange={setFieldValue}
                      currentFacilityBins={currentFacilityBins}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <StatusInput
                      onChange={setFieldValue}
                      value={values.statusArr}
                      assetsByFacilityStatusSearch={
                        assetsByFacilityStatusSearch
                      }
                      handleSubmit={handleSubmit}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <ItemInput
                      onChange={setFieldValue}
                      value={values.productIdArr}
                      productQuantitiesbyCategorySearch={
                        productQuantitiesbyCategorySearch
                      }
                      handleSubmit={handleSubmit}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <CategoryInput
                      onChange={setFieldValue}
                      value={values.categoryIdArr}
                      productQuantitiesbyCategorySearch={
                        productQuantitiesbyCategorySearch
                      }
                      handleSubmit={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Box>
              <div
                style={{
                  textAlign: "center",
                  margin: "2rem 0",
                  display: "flex",
                  justifyContent: "center",
                  gap: "2rem",
                }}
              >
                {searchResultData?.assetsSearch.length > 0 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    disabled={isSubmitting || loadingExcel}
                    onClick={downloadExcel}
                  >
                    {loadingExcel ? (
                      <CircularProgress size={25} color="inherit" />
                    ) : (
                      "Export"
                    )}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Search
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
      {variables && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: "60vh",
                width: "80vw",
              }}
            >
              <AssetTable
                variables={variables}
                facilityList={facilityList}
                setVariables={setVariables}
                setSearchResultData={setSearchResultData}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </TitleWrapper>
  );
}
