import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export function StorageBinCombo(props) {
  return (
    <Autocomplete
      id="storage-bin-combo"
      options={props.currentFacilityBins}
      getOptionLabel={option => option.code}
      onChange={(event, newValue) => {
        if (newValue) {
          props.onChange(props.name, newValue["code"]);
        }
      }}
      value={
        props.currentFacilityBins.find(item => item.code === props.value) ||
        null
      }
      renderInput={params => (
        <TextField
          {...params}
          label="Storage Bin"
          variant="outlined"
          required
        />
      )}
      disabled={props.disabled}
    />
  );
}
