import { gql, useQuery } from "@apollo/client";

const useProductsCountsByCategory = () => {
  const { data, loading, error } = useQuery(
    gql`
      {
        countProductsByCategory {
          category
          product
          totalQuantity
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );
  return { data, loading, error };
};
export default useProductsCountsByCategory;
