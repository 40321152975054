import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import {
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

import Title from "./Title";
import Loading from "../Loading";
import useAssetsTransferTimes from "../../graphql/useAssetsTransferTimes";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default function AssetsTransferTimes(props) {
  const [assetDataOriginal, setAssetDataOriginal] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [searchText, setSearchText] = React.useState("");
  const { data, loading, error } = useAssetsTransferTimes();
  const history = useHistory();

  const requestSearch = searchValue => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");

    if (searchValue === "") {
      setAssetData(assetDataOriginal);
    } else {
      const filteredRows = assetData.filter(row => {
        return Object.keys(row).some(field => {
          return searchRegex.test(row[field].toString());
        });
      });
      setAssetData(filteredRows);
    }
  };

  useEffect(() => {
    if (data) {
      const row = {
        id: null,
        facility: null,
        status: null,
        tagCode: null,
        customCode: null,
        transferStart: null,
        transferTime: null,
      };

      let rows = [];

      data["assetsTransferTimes"].forEach(e => {
        let thisRow = Object.create(row);

        thisRow.id = e.assetId;
        thisRow.facility = props.facilities[e.facilityId];
        thisRow.status = e.status;
        thisRow.tagCode = e.tagCode;
        thisRow.customCode = e.customCode;
        thisRow.transferStart = new Date(e.transferStart);
        thisRow.transferTime = e.transferTime;

        rows.push(thisRow);
      });

      // rows.forEach((thisRow, index) => {
      //   thisRow.id = index;
      //   thisRow.total = thisRow.stored + thisRow.transfer + thisRow.received;
      // })

      setAssetDataOriginal(rows);
      setAssetData(rows);
    }
  }, [data]);

  const [sortModel, setSortModel] = React.useState([
    {
      field: "transferStart",
      sort: "asc",
    },
  ]);

  if (loading) return <Loading />;
  if (error) return <h2>Error loading Assets Transfer Times!</h2>;

  return (
    <>
      <Title>Assets Transfer Times</Title>
      <div
        style={{
          display: "flex",
          height: 400,
          width: "100%",
        }}
      >
        <DataGrid
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: event => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
          rows={assetData}
          columns={columns}
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
          density="compact"
          onCellClick={e => {
            if (e.field === "facility") {
              history.push("/search-asset", {
                assetsTransferTimesSearch: {
                  facility: e.row.facility,
                },
              });
            }
            if (e.field === "tagCode") {
              history.push("/search-asset", {
                assetsTransferTimesSearch: {
                  tagCode: e.row.tagCode,
                },
              });
            }
          }}
        />
      </div>
    </>
  );
}

const columns = [
  {
    field: "tagCode",
    headerName: "Tag Code",
    width: 140,
    renderCell: params =>
      params.value ? <Button>{params.value}</Button> : null,
  },
  {
    field: "facility",
    headerName: "Facility",
    type: "string",
    minWidth: 150,
    flex: 1,
    renderCell: params =>
      params.value ? <Button>{params.value}</Button> : null,
  },
  {
    field: "transferTime",
    headerName: "Transfer Time",
    type: "date",
    width: 200,
  },
  {
    field: "transferStart",
    headerName: "Transfer Start Date",
    type: "string",
    width: 200,
  },
];
