import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import useAssetsByFilter from "../graphql/useAssetsByFilter";
import useAssetDetails from "../graphql/useAssetDetails";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Modal } from "@mui/material";
import Loading from "./Loading";
import NewAssetForm from "./Dashboard/NewAssetForm/NewAssetForm";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "1100px",
  maxHeight: "80vh",
  overflowY: "scroll",
  background: "#fff",
  padding: "2rem",
};

export default function AssetTable({
  variables,
  setVariables,
  setSearchResultData,
}) {
  const [rowsData, setRowsData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentAsset, setCurrentAsset] = useState(null);

  const { getAssetsByFilter, data, loading, error } =
    useAssetsByFilter(variables);

  const {
    getAssetDetails,
    data: detailsData,
    loading: loadingDetails,
    error: errorDetails,
  } = useAssetDetails();

  useEffect(() => {
    getAssetsByFilter({ variables });
  }, [variables, getAssetsByFilter]);

  useEffect(() => {
    setSearchResultData(data);
  }, [data, setSearchResultData]);

  const columns = [
    { field: "tagCode", headerName: "Tag Code", width: 150 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "eventDate", headerName: "Pack Date", width: 200 },
    { field: "facility", headerName: "Facility", width: 150 },
    { field: "bin", headerName: "Bin", width: 150 },
    {
      field: "action",
      headerName: "Details",
      sortable: false,
      renderCell: params => {
        const onClick = () => {
          setCurrentItems(params.row.items);
          getAssetDetails({
            variables: {
              productIdArr: params.row.items.map(item => item.itemId),
            },
          });
        };

        return params.row.items.length ? (
          <Button onClick={onClick}>Show</Button>
        ) : null;
      },
    },
    {
      field: "edit",
      headerName: "",
      sortable: false,
      renderCell: params => {
        const onClick = () => {
          setCurrentAsset(params.row);
        };

        return params.row.status === "STORED" ||
          params.row.status === "TRANSFER" ||
          params.row.status === "RECEIVED" ? (
          <Button onClick={onClick}>Edit</Button>
        ) : null;
      },
    },
  ];

  useEffect(() => {
    if (data) {
      let dataCopy = [];
      data.assetsSearch.forEach(e => {
        let row = Object.assign({}, e);
        row.facility = e.facilityId.name;
        row.tagCode = e.customCode;
        row.bin = e.binCode;
        dataCopy.push(row);
      });
      setRowsData(dataCopy);
    }
  }, [data]);

  if (error) return <h2>Error loading Assets!</h2>;

  return (
    <>
      <Modal
        open={currentItems.length ? true : false}
        onClose={() => setCurrentItems([])}
      >
        <Box sx={{ margin: 1 }} style={modalStyle}>
          <Typography variant="h6" gutterBottom component="div">
            Details
          </Typography>
          {loadingDetails && currentItems.length ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Loading />
            </div>
          ) : errorDetails ? (
            <p>Error loading details!</p>
          ) : (
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Code</TableCell>
                  <TableCell>Product name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Subcategory</TableCell>
                  <TableCell>Item Notes</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Is Priced?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detailsData?.products &&
                  detailsData.products.map(itemRow => {
                    const currentProduct = currentItems.find(
                      item => item.itemId === itemRow._id
                    );
                    return currentProduct ? (
                      <TableRow key={itemRow._id}>
                        <TableCell component="th" scope="row">
                          {itemRow.customCode}
                        </TableCell>
                        <TableCell>{itemRow.name}</TableCell>
                        <TableCell>{itemRow.categoryId.name}</TableCell>
                        <TableCell>{itemRow.subcategory}</TableCell>
                        <TableCell>{currentProduct.notes}</TableCell>
                        <TableCell align="right">
                          {currentProduct.quantity}
                        </TableCell>
                        <TableCell align="right">
                          {currentProduct.isPriced ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                    ) : null;
                  })}
              </TableBody>
            </Table>
          )}
        </Box>
      </Modal>
      <Modal
        open={currentAsset ? true : false}
        onClose={() => setCurrentAsset(null)}
      >
        <Box sx={{ margin: 1 }} style={modalStyle}>
          {currentAsset && (
            <NewAssetForm
              assetToEdit={currentAsset}
              onCloseModal={() => {
                setCurrentAsset(null);
                getAssetsByFilter({ variables });
              }}
            />
          )}
        </Box>
      </Modal>
      <DataGrid
        rows={rowsData}
        columns={columns}
        getRowId={row => row._id}
        disableSelectionOnClick
        pagination
        pageSize={100}
        rowsPerPageOptions={[100]}
        rowCount={
          rowsData.length === 100
            ? variables.offset === 0
              ? 200
              : variables.offset + 100
            : variables.offset === 0
            ? 100
            : variables.offset
        }
        onPageChange={newPage => {
          setVariables({
            ...variables,
            offset: newPage === 0 ? 0 : (newPage + 1) * 100,
          });
        }}
        paginationMode="server"
        loading={loading}
      />
    </>
  );
}
