import React, { useEffect, useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

import Alert from "@mui/material/Alert";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import { Typography, Box } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { UPDATE_ASSET_INFO } from "./queries";
import { StoreLocationCombo } from "./NewAssetLocation";
import { StorageBinCombo } from "./NewAssetBin";
import { StatusSelect } from "./NewAssetStatusSelect";
import { AssetProduct } from "./NewAssetProduct";
import TitleWrapper from "../TitleWrapper";
import useProductsByIds from "../../../graphql/useProductsByIds";
import Loading from "../../Loading";

const formTheme = createTheme({
  typography: {
    fontFamily: ["Roboto", '"Segoe UI"', '"Helvetica Neue"'].join(","),
  },
});

let now = new Date();
now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

const productSet = {
  type: "product",
  product: null,
  notes: "",
  quantity: 0,
  isPriced: false,
};

function NewAssetForm({ assetToEdit, onCloseModal }) {
  const [
    updateAssetInfo,
    { loading: assetInfoLoading, error: assetInfoError, data: assetInfoData },
  ] = useMutation(UPDATE_ASSET_INFO);

  const [getProductsByIds, { error, data }] = useProductsByIds();

  const [currentFacilityBins, setCurrentFacilityBins] = useState([]);

  const [successAlert, setSuccessAlert] = useState(false);

  const addItemRef = useRef();

  useEffect(() => {
    if (assetToEdit && assetToEdit?.items?.length) {
      getProductsByIds({
        variables: { ids: assetToEdit.items.map(item => item.itemId) },
      });
    }
  }, [assetToEdit, getProductsByIds]);

  useEffect(() => {
    const shortcut = e => {
      if (e.code === "KeyA" && e.altKey) {
        addItemRef.current.click();
      }
    };
    document.addEventListener("keydown", shortcut);
    return () => {
      document.removeEventListener("keydown", shortcut);
    };
  }, []);

  if (error) {
    return <div>Error loading asset items</div>;
  }

  if (assetToEdit && assetToEdit?.items?.length && !data) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Loading />
      </div>
    );
  }

  return (
    <TitleWrapper
      title={assetToEdit ? "Edit Asset" : "New Asset"}
      titleOnUnmounted={assetToEdit ? "Search Asset" : undefined}
    >
      <Formik
        initialValues={
          assetToEdit
            ? {
                tagCode: assetToEdit.tagCode,
                status: assetToEdit.status,
                facility: "",
                bin: assetToEdit.binCode,
                eventDate: assetToEdit.eventDate
                  ? new Date(assetToEdit.eventDate).toISOString().slice(0, 16)
                  : now.toISOString().slice(0, 16),
                eventType: "packed",
                items: assetToEdit?.items?.length
                  ? assetToEdit.items.map(item => {
                      return {
                        ...item,
                        product: data.products.find(
                          itemProduct => itemProduct._id === item.itemId
                        ),
                      };
                    })
                  : [],
              }
            : {
                tagCode: "",
                status: "STORED",
                facility: "",
                bin: null,
                eventDate: now.toISOString().slice(0, 16),
                eventType: "packed",
                items: [productSet],
              }
        }
        onSubmit={async (values, { resetForm }) => {
          let eventDate_utc = new Date(values.eventDate);
          const res = await updateAssetInfo({
            variables: {
              tagCode: values.tagCode,
              status: values.status,
              facilityId: { link: values.facility },
              binCode: values.bin,
              eventDate: eventDate_utc,
              eventType: values.eventType,
              items: values.items.map(item => ({
                type: "product",
                itemId: item.product._id,
                quantity: item.quantity,
                isPriced: item.isPriced,
                notes: item.notes,
              })),
            },
          });
          if (res.data && res.data.updateOneAsset !== null) {
            setSuccessAlert(true);
            if (!assetToEdit) {
              resetForm();
            }
          }
        }}
        validationSchema={Yup.object().shape({
          tagCode: Yup.string().required("Required"),
          status: Yup.string().required("Required"),
          facility: Yup.string().required("Required"),
          bin: Yup.string().nullable(),
          eventDate: Yup.date(),
          eventType: Yup.string(),
          items: Yup.array(),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            resetForm,
          } = props;

          return assetInfoLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Loading />
            </div>
          ) : (
            <React.Fragment>
              <ThemeProvider theme={formTheme}>
                {successAlert && (
                  <Alert severity="success">Asset successfully updated.</Alert>
                )}
                {assetInfoError && (
                  <Alert severity="error">
                    An error occurred. Please try again.
                  </Alert>
                )}
                {assetInfoData?.updateOneAsset === null && (
                  <Alert severity="error">
                    Asset not created. Please check that the Asset Tag Code is
                    valid.
                  </Alert>
                )}
                <form onSubmit={handleSubmit} className="assetForm">
                  <Container maxWidth="md">
                    <Grid container spacing={3} style={{ textAlign: "center" }}>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography variant="h3" color="#1976d2">
                          {assetToEdit ? "Edit Asset" : "New Asset"}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          id="tagCode"
                          name="tagCode"
                          label="Asset Tag Code"
                          variant="outlined"
                          type="text"
                          fullWidth
                          value={values.tagCode}
                          onChange={event => {
                            if (event) {
                              setFieldValue(
                                "tagCode",
                                event.target.value.toUpperCase()
                              );
                            }
                          }}
                          disabled={assetToEdit ? true : false}
                          onBlur={handleBlur}
                          onReset={handleReset}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <StoreLocationCombo
                          id="facility"
                          name="facility"
                          defaultFacilityName={
                            assetToEdit ? assetToEdit.facility : "Crosstown HUB"
                          }
                          value={values.facility}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          onReset={handleReset}
                          setCurrentFacilityBins={setCurrentFacilityBins}
                          assetToEdit={assetToEdit}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <StorageBinCombo
                          id="bin"
                          name="bin"
                          value={values.bin}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          currentFacilityBins={currentFacilityBins}
                          disabled={!currentFacilityBins.length}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          id="eventDate"
                          name="eventDate"
                          value={values.eventDate}
                          variant="outlined"
                          type="datetime-local"
                          label="Pack Date"
                          onChange={event => {
                            if (event) {
                              setFieldValue("eventDate", event.target.value);
                            }
                          }}
                          onBlur={handleBlur}
                          onReset={handleReset}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <StatusSelect
                          id="status"
                          name="status"
                          value={values.status}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          onReset={handleReset}
                        />
                      </Grid>
                    </Grid>
                  </Container>

                  <Container maxWidth="md">
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "center", marginTop: "2rem" }}
                      >
                        <Typography variant="h4" color="#1976d2">
                          Add Items
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <FieldArray
                          name="items"
                          render={({ insert, remove, push }) => (
                            <>
                              {values.items.length > 0 &&
                                values.items.map((product, index) => (
                                  <Grid
                                    container
                                    spacing={3}
                                    key={index}
                                    marginBottom={2}
                                  >
                                    <Grid item xs={3}>
                                      <AssetProduct
                                        id="product"
                                        name={`items.${index}.product`}
                                        label="Item"
                                        variant="outlined"
                                        type="text"
                                        value={values.items[index].product}
                                        onChange={setFieldValue}
                                        onBlur={handleBlur}
                                        onReset={handleReset}
                                        helperText={
                                          errors.items &&
                                          errors.items[index] &&
                                          errors.items[index].product &&
                                          touched.items &&
                                          touched.items[index].product
                                            ? errors.items[index].product
                                            : ""
                                        }
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <TextField
                                        id="quantity"
                                        name={`items.${index}.quantity`}
                                        label="Quantity"
                                        type="number"
                                        variant="outlined"
                                        value={values.items[index].quantity}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={
                                          errors.items &&
                                          errors.items[index] &&
                                          errors.items[index].notes &&
                                          touched.items &&
                                          touched.items[index].notes
                                            ? errors.items[index].notes
                                            : ""
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>

                                    <Grid item xs={2}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            id="isPriced"
                                            name={`items.${index}.isPriced`}
                                            color="primary"
                                            checked={
                                              values.items[index].isPriced
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        }
                                        label="Priced?"
                                      />
                                    </Grid>

                                    <Grid item xs={3}>
                                      <TextField
                                        id="notes"
                                        name={`items.${index}.notes`}
                                        label="Item Notes"
                                        variant="outlined"
                                        type="text"
                                        value={values.items[index].notes}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={
                                          errors.items &&
                                          errors.items[index] &&
                                          errors.items[index].notes &&
                                          touched.items &&
                                          touched.items[index].notes
                                            ? errors.items[index].notes
                                            : ""
                                        }
                                      />
                                    </Grid>

                                    <Grid item xs={1}>
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => remove(index)}
                                      >
                                        Delete
                                      </Button>
                                    </Grid>
                                  </Grid>
                                ))}

                              <Box p={1} />

                              <Grid
                                item
                                xs={12}
                                marginBottom={2}
                                style={{ textAlign: "center" }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() => push(productSet)}
                                  disabled={isSubmitting}
                                  ref={addItemRef}
                                >
                                  Add Item
                                </Button>
                              </Grid>
                            </>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Container>

                  <Container maxWidth="md">
                    <Grid container spacing={3} style={{ textAlign: "center" }}>
                      {assetToEdit ? (
                        <Grid item xs={6}>
                          <Button
                            variant="outlined"
                            onClick={onCloseModal}
                            disabled={!dirty || isSubmitting}
                          >
                            Close
                          </Button>
                        </Grid>
                      ) : (
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            onClick={resetForm}
                            disabled={!dirty || isSubmitting}
                          >
                            Reset
                          </Button>
                        </Grid>
                      )}

                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {assetToEdit ? "Edit" : "Create"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </form>
              </ThemeProvider>
            </React.Fragment>
          );
        }}
      </Formik>
    </TitleWrapper>
  );
}

export default NewAssetForm;
