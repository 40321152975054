import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { GET_FACILITIES } from "./queries";
import useGetEmptyBins from "../../../graphql/useGetEmptyBins";

export function StoreLocationCombo({
  setCurrentFacilityBins,
  defaultFacilityName = "Crosstown HUB",
  onChange,
  value,
  name,
  id,
  assetToEdit,
}) {
  const [storeLocation, setStoreLocation] = useState([]);
  const { data } = useQuery(GET_FACILITIES);
  const { getEmptyBins, data: dataEmptyBins, loading } = useGetEmptyBins();
  const defaultFacility =
    storeLocation.find(item => item.name === defaultFacilityName) || null;

  useEffect(() => {
    if (data) {
      setStoreLocation(data["facilities"]);
      if (!value && defaultFacility) {
        onChange(name, defaultFacility._id);
        if (setCurrentFacilityBins) {
          if (defaultFacility.bins?.length) {
            getEmptyBins({
              variables: {
                facilityId: defaultFacility._id,
              },
            });
          } else {
            setCurrentFacilityBins([]);
          }
        }
      }
    }
  }, [
    data,
    setCurrentFacilityBins,
    name,
    defaultFacility,
    getEmptyBins,
    onChange,
    value,
  ]);

  useEffect(() => {
    if (!loading && dataEmptyBins) {
      if (dataEmptyBins?.getEmptyBins?.emptyBins?.length) {
        if (setCurrentFacilityBins) {
          const newArray = dataEmptyBins.getEmptyBins.emptyBins.map(item => ({
            code: item,
          }));
          if (assetToEdit?.binCode) {
            setCurrentFacilityBins([
              ...newArray,
              { code: assetToEdit?.binCode },
            ]);
          } else {
            setCurrentFacilityBins(newArray);
          }
        }
      } else {
        onChange("bin", null);
        if (setCurrentFacilityBins) {
          setCurrentFacilityBins([]);
        }
      }
    }
  }, [dataEmptyBins, setCurrentFacilityBins, onChange, loading, assetToEdit]);

  return (
    <Autocomplete
      id={id}
      options={storeLocation}
      onChange={(event, newValue) => {
        if (newValue) {
          onChange(name, newValue["_id"]);
          console.log(newValue);
          if (newValue.bins?.length) {
            getEmptyBins({
              variables: {
                facilityId: defaultFacility._id,
              },
            });
          } else {
            onChange("bin", null);
            if (setCurrentFacilityBins) {
              setCurrentFacilityBins([]);
            }
          }
        }
      }}
      value={storeLocation.find(item => item._id === value) || defaultFacility}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField {...params} label="Facility" variant="outlined" required />
      )}
    />
  );
}
