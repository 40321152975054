import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import {
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

import Title from "./Title";
import useGetEmptyBins from "../../graphql/useGetEmptyBins";
import Loading from "../Loading";
import useFacilities from "../../graphql/useFacilities";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default function EmptyBins() {
  const [binDataOriginal, setBinDataOriginal] = useState([]);
  const [binData, setBinData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { data: facilitiesData } = useFacilities();
  const { getEmptyBins, data, loading, error } = useGetEmptyBins();

  useEffect(() => {
    if (facilitiesData.facilities) {
      const crosstwonHubID = facilitiesData.facilities.find(
        item => item.code === "HUB"
      )._id;

      getEmptyBins({
        variables: {
          facilityId: crosstwonHubID,
        },
      });
    }
  }, [facilitiesData, getEmptyBins]);

  const requestSearch = searchValue => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");

    if (searchValue === "") {
      setBinData(binDataOriginal);
    } else {
      const filteredRows = binData.filter(row => {
        return Object.keys(row).some(field => {
          return searchRegex.test(row[field].toString());
        });
      });
      setBinData(filteredRows);
    }
  };

  useEffect(() => {
    if (data) {
      const row = {
        id: null,
        state: null,
      };

      let rows = [];

      const allBins = data["getEmptyBins"];
      const emptyBins = allBins["emptyBins"];
      const takenBins = allBins["takenBins"];

      emptyBins.forEach(e => {
        let thisRow = Object.create(row);

        thisRow.id = e;
        thisRow.state = "Empty";

        rows.push(thisRow);
      });

      takenBins.forEach(e => {
        let thisRow = Object.create(row);

        thisRow.id = e;
        thisRow.state = "Filled";

        rows.push(thisRow);
      });

      // Set a copy for when the search bar is cleared
      setBinDataOriginal(rows);
      setBinData(rows);
    }
  }, [data]);

  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "asc",
    },
  ]);

  if (loading) return <Loading />;
  if (error) return <h2>Error loading Empty Bins!</h2>;

  return (
    <>
      <Title>Bins Vacancy</Title>
      <div style={{ display: "flex", height: "100vh", width: "100%" }}>
        <DataGrid
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: event => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
          rows={binData}
          columns={columns}
          disableSelectionOnClick
          density="compact"
        />
      </div>
    </>
  );
}

const columns = [
  {
    field: "id",
    headerName: "Bin Code",
    type: "string",
    minWidth: 140,
    flex: 0.4,
  },
  {
    field: "state",
    headerName: "State",
    type: "string",
    minWidth: 140,
    flex: 0.6,
  },
];
