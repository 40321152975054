import { createTheme } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import useInsertProduct from "../graphql/useInsertProduct";
import { ThemeProvider } from "@mui/styles";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import CategoryInput from "./Dashboard/SearchInputs/CategoryInput";
import TitleWrapper from "./Dashboard/TitleWrapper";

const formTheme = createTheme({
  typography: {
    fontFamily: ["Roboto", '"Segoe UI"', '"Helvetica Neue"'].join(","),
  },
});

const NewProductForm = () => {
  const [insertProduct, { data, loading, error }] = useInsertProduct();
  const [successAlert, setSuccessAlert] = useState(false);

  useEffect(() => {
    if (data) {
      setSuccessAlert(true);
    }
  }, [data]);

  if (loading) {
    return "Sending new product...";
  }

  return (
    <TitleWrapper title="New Product">
      <Formik
        initialValues={{
          tagCode: "",
          customCode: "",
          name: "",
          description: "",
          categoryId: "",
        }}
        onSubmit={async values => {
          await insertProduct({
            variables: values,
          });
        }}
        validationSchema={Yup.object().shape({
          tagCode: Yup.string(),
          customCode: Yup.string().required("Required"),
          name: Yup.string().required("Required"),
          description: Yup.string(),
          categoryId: Yup.string().required("Required"),
        })}
      >
        {props => {
          const {
            isSubmitting,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
          } = props;

          return (
            <>
              <ThemeProvider theme={formTheme}>
                {successAlert && (
                  <Alert severity="success">
                    Product successfully created.
                  </Alert>
                )}
                {error && (
                  <Alert severity="error">
                    {error.graphQLErrors.map(({ message }, i) => (
                      <span key={i}>{message}</span>
                    ))}
                  </Alert>
                )}
                <form onSubmit={handleSubmit}>
                  <Container maxWidth="md">
                    <Grid container spacing={3} marginBottom={2}>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography variant="h3" color="#1976d2">
                          New Product
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        style={{ marginTop: "1rem" }}
                      >
                        <Grid item xs={2} sm={4} md={6}>
                          <TextField
                            id="name"
                            name="name"
                            label="Product Name"
                            variant="outlined"
                            fullWidth
                            type="text"
                            onChange={event => {
                              if (event) {
                                setFieldValue("name", event.target.value);
                              }
                            }}
                            onBlur={handleBlur}
                            required
                          />
                        </Grid>
                        {/* <Grid item xs={2} sm={4} md={6}>
                        <TextField
                          id="tagCode"
                          name="tagCode"
                          label="Tag Code"
                          variant="outlined"
                          fullWidth
                          type="text"
                          value={values.tagCode}
                          onChange={event => {
                            if (event) {
                              setFieldValue(
                                "tagCode",
                                event.target.value.toUpperCase()
                              );
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid> */}
                        <Grid item xs={2} sm={4} md={6}>
                          <TextField
                            id="customCode"
                            name="customCode"
                            label="Custom Code"
                            variant="outlined"
                            fullWidth
                            type="text"
                            value={values.customCode}
                            onChange={event => {
                              if (event) {
                                setFieldValue(
                                  "customCode",
                                  event.target.value.toUpperCase()
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            required
                          />
                        </Grid>
                        <Grid item xs={2} sm={4} md={6}>
                          <CategoryInput
                            onChange={setFieldValue}
                            type="newProduct"
                          />
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                          <TextField
                            id="description"
                            name="description"
                            label="Description"
                            variant="outlined"
                            type="text"
                            multiline
                            rows={4}
                            fullWidth
                            onChange={event => {
                              if (event) {
                                setFieldValue(
                                  "description",
                                  event.target.value
                                );
                              }
                            }}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>

                  <Container maxWidth="md">
                    <Grid
                      container
                      spacing={12}
                      style={{ textAlign: "center" }}
                    >
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </form>
              </ThemeProvider>
            </>
          );
        }}
      </Formik>
    </TitleWrapper>
  );
};

export default NewProductForm;
