import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import useCategories from "../../../graphql/useCategories";

const CategoryInput = ({
  onChange,
  type,
  productQuantitiesbyCategorySearch,
  value,
  handleSubmit,
}) => {
  const [categories, setCategories] = useState([]);
  const { data } = useCategories();

  useEffect(() => {
    if (data) {
      setCategories(data.categories);
      if (productQuantitiesbyCategorySearch) {
        const categoryObject = data.categories.find(
          item => item.name === productQuantitiesbyCategorySearch.category
        );
        if (categoryObject) {
          onChange("categoryIdArr", [categoryObject]);
          handleSubmit();
        }
      }
    }
  }, [data, productQuantitiesbyCategorySearch, onChange, handleSubmit]);

  return (
    <Autocomplete
      multiple={type && type === "newProduct" ? false : true}
      options={categories}
      onChange={(event, newValue) => {
        if (type && type === "newProduct") {
          onChange("categoryId", newValue._id);
        } else {
          onChange("categoryIdArr", newValue);
        }
      }}
      getOptionLabel={option => option.name}
      value={value}
      renderInput={params => <TextField {...params} label="Categories" />}
    />
  );
};

export default CategoryInput;
