import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import {
  DataGrid,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

import Title from "./Title";
import useProductsCountsByCategory from "../../graphql/useProductsCountsByCategory";
import Loading from "../Loading";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default function ProductsCountsByCategory() {
  const [assetDataOriginal, setAssetDataOriginal] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { data, loading, error } = useProductsCountsByCategory();
  const history = useHistory();

  const requestSearch = searchValue => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");

    if (searchValue === "") {
      setAssetData(assetDataOriginal);
    } else {
      const filteredRows = assetData.filter(row => {
        return Object.keys(row).some(field => {
          return searchRegex.test(row[field].toString());
        });
      });
      setAssetData(filteredRows);
    }
  };

  useEffect(() => {
    if (data) {
      const row = {
        id: null,
        category: null,
        product: null,
        totalQuantity: null,
      };

      let rows = [];
      let productCounts = data["countProductsByCategory"];

      let categoryCounts = [];
      productCounts.reduce(function (res, value) {
        if (!res[value.category]) {
          res[value.category] = {
            category: value.category,
            totalQuantity: 0,
          };
          categoryCounts.push(res[value.category]);
        }
        res[value.category].totalQuantity += value.totalQuantity;
        return res;
      }, {});

      categoryCounts.forEach(e => {
        let thisRow = Object.create(row);

        thisRow.id = e.category;
        thisRow.category = e.category;
        thisRow.product = "All Products";
        thisRow.totalQuantity = e.totalQuantity;

        rows.push(thisRow);
      });

      productCounts.forEach(e => {
        let thisRow = Object.create(row);

        thisRow.id = e.product;
        thisRow.category = e.category;
        thisRow.product = e.product;
        thisRow.totalQuantity = e.totalQuantity;

        rows.push(thisRow);
      });

      // Set a copy for when the search bar is cleared
      setAssetDataOriginal(rows);
      setAssetData(rows);
    }
  }, [data]);

  const [sortModel, setSortModel] = useState([
    {
      field: "totalQuantity",
      sort: "desc",
    },
  ]);

  if (loading) return <Loading />;
  if (error) return <h2>Error loading Assets by Facility and Status!</h2>;

  return (
    <>
      <Title>Warehouse Overview: Product Quantities by Category</Title>
      <div style={{ display: "flex", height: "100vh", width: "100%" }}>
        <DataGrid
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: event => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
          rows={assetData}
          columns={columns}
          disableSelectionOnClick
          density="compact"
          onCellClick={e => {
            if (e.field === "totalQuantity") {
              history.push("/search-asset", {
                productQuantitiesbyCategorySearch: {
                  category: e.row.category,
                  product: e.row.product,
                },
              });
            }
          }}
        />
      </div>
    </>
  );
}

const columns = [
  {
    field: "category",
    headerName: "Category",
    type: "string",
    minWidth: 140,
    flex: 0.3,
  },
  {
    field: "product",
    headerName: "Product",
    type: "string",
    minWidth: 140,
    flex: 0.3,
  },
  {
    field: "totalQuantity",
    headerName: "Total Quantity",
    type: "number",
    minWidth: 200,
    flex: 0.4,
    renderCell: params =>
      params.value ? <Button>{params.value}</Button> : null,
  },
];
