import { gql, useMutation } from "@apollo/client";

const useInsertCategory = variables => {
  const [insertCategory, { data, loading, error }] = useMutation(
    gql`
    mutation insertCategory(
      $name: String
    ) {
      insertCategory(input: $name) {
        _id
        name
      }
    }
    `,
    { variables: variables }
  );
  return [insertCategory, { data, loading, error }];
};
export default useInsertCategory;