import { gql, useQuery } from "@apollo/client";

const useAssetsByFacilityStatus = () => {
  const { data, loading, error } = useQuery(
    gql`
      {
        assetsByFacilityStatus {
          facility
          counts {
            status
            count
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );
  return { data, loading, error };
};
export default useAssetsByFacilityStatus;
