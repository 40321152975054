import React from "react";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function StatusSelect(props) {
  const classes = useStyles();

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="status-select-internal-label">Status</InputLabel>
        <Select
          name={props.name}
          labelId="demo-simple-select-outlined-label"
          id="status"
          label="Status"
          required
          value={props.value}
          onChange={(event, newValue) => {
            if (event) {
              props.onChange("status", event.target.value);
            }
          }}
          onReset={props.onReset}
        >
          <MenuItem value="STORED">Stored</MenuItem>
          <MenuItem value="TRANSFER">Transfer</MenuItem>
          <MenuItem value="RECEIVED">Received</MenuItem>
          <MenuItem value="RETIRED">Retired</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
