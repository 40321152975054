import React from "react";
import useRfidHistory from "../../graphql/useRfidHistory";
import { DataGrid } from "@mui/x-data-grid";
import useFacilities from "../../graphql/useFacilities";

const TagsHistoryTable = ({ variables }) => {
  const { data, loading, error } = useRfidHistory(variables);
  const { data: facilitiesData } = useFacilities();

  const columns = [
    { field: "rfidCode", headerName: "RFID code", width: 250 },
    {
      field: "facilityId",
      headerName: "Facility",
      width: 200,
      renderCell: params =>
        facilitiesData.facilities
          ? facilitiesData.facilities.find(
              item => item._id === params.row.facilityId
            ).name
          : params.row.facilityId,
    },
    { field: "date", headerName: "Date", width: 250 },
    {
      field: "found",
      headerName: "Found",
      width: 150,
      renderCell: params => (params.row.found ? "Yes" : "No"),
    },
  ];

  if (loading) return <h2>Loading Assets ...</h2>;
  if (error) return <h2>Error loading Assets!</h2>;

  return (
    <DataGrid
      rows={data.rfidHistories}
      columns={columns}
      pageSize={100}
      getRowId={row => row._id}
      rowsPerPageOptions={[100]}
      disableSelectionOnClick
    />
  );
};

export default TagsHistoryTable;
