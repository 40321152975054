import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useHistory } from "react-router-dom";

export const MainListItems = () => {
  const history = useHistory();

  return (
    <div>
      <ListItem button onClick={() => history.push("/")}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </div>
  );
};

export const SecondaryListItems = () => {
  const history = useHistory();

  return (
    <div>
      <ListSubheader inset>Assets</ListSubheader>
      <ListItem button onClick={() => history.push("/new-asset")}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="New Asset" />
      </ListItem>
      <ListItem button onClick={() => history.push("/search-asset")}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Search Assets" />
      </ListItem>
      <ListItem button onClick={() => history.push("/tags-history")}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Tags History" />
      </ListItem>

      <ListSubheader inset>Other</ListSubheader>
      <ListItem button onClick={() => history.push("/new-category")}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Add New Category" />
      </ListItem>
      <ListItem button onClick={() => history.push("/new-product")}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Add New Product" />
      </ListItem>
    </div>
  );
};
