import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_FACILITIES } from "../NewAssetForm/queries";

const FacilityInput = ({
  onChange,
  setCurrentFacilityBins,
  setFacilityList,
  assetsByFacilityStatusSearch,
  assetsTransferTimesSearch,
  handleSubmit,
  value,
}) => {
  const [facilities, setFacilities] = useState([]);
  const { data } = useQuery(GET_FACILITIES);

  useEffect(() => {
    if (data) {
      setFacilities(data["facilities"]);

      let f = {};
      data["facilities"].forEach(e => {
        f[e._id] = e.name;
      });
      setFacilityList(f);

      if (
        assetsByFacilityStatusSearch &&
        assetsByFacilityStatusSearch.facility
      ) {
        const facilityObject = data.facilities.find(
          item => item.name === assetsByFacilityStatusSearch.facility
        );
        if (facilityObject) {
          onChange("facilityIdArr", [facilityObject]);
          const bins = [facilityObject]
            .filter(item => item.bins)
            .map(item => {
              const currentBins = item.bins.map(bin => bin.code);
              return currentBins;
            })
            .flat();

          setCurrentFacilityBins(bins);
          handleSubmit();
        }
      }
      if (assetsTransferTimesSearch && assetsTransferTimesSearch.facility) {
        const facilityObject = data.facilities.find(
          item => item.name === assetsTransferTimesSearch.facility
        );
        if (facilityObject) {
          onChange("facilityIdArr", [facilityObject]);
          const bins = [facilityObject]
            .filter(item => item.bins)
            .map(item => {
              const currentBins = item.bins.map(bin => bin.code);
              return currentBins;
            })
            .flat();

          setCurrentFacilityBins(bins);
          handleSubmit();
        }
      }
    }
  }, [
    data,
    setFacilityList,
    onChange,
    assetsByFacilityStatusSearch,
    handleSubmit,
    setCurrentFacilityBins,
    assetsTransferTimesSearch,
  ]);

  return (
    <Autocomplete
      multiple
      onChange={(event, newValue) => {
        onChange("facilityIdArr", newValue);

        const bins = newValue
          .filter(item => item.bins)
          .map(item => {
            const currentBins = item.bins.map(bin => bin.code);
            return currentBins;
          })
          .flat();

        setCurrentFacilityBins(bins);
      }}
      options={facilities}
      value={value}
      getOptionLabel={option => option.name}
      filterSelectedOptions
      renderInput={params => <TextField {...params} label="Facilities" />}
    />
  );
};

export default FacilityInput;
