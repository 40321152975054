import { gql, useMutation } from "@apollo/client";

const useInsertProduct = variables => {
  const [insertProduct, { data, loading, error }] = useMutation(
    gql`
    mutation insertProduct(
        $tagCode: String,
        $customCode: String,
        $name: String,
        $description: String,
        $categoryId: ObjectId
    ){
        insertProduct(input: {
        tagCode: $tagCode,
        customCode: $customCode,
        name: $name,
        description: $description,
        categoryId: {link: $categoryId}
        }) {
        _id
        tagCode
        customCode
        name
        description
        categoryId {
            _id
            name
        }
        }
    }
    `,
    { variables: variables }
  );
  return [insertProduct, { data, loading, error }];
};
export default useInsertProduct;