import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const BinsInput = ({ onChange, currentFacilityBins }) => {
  return (
    <Autocomplete
      multiple
      options={currentFacilityBins}
      onChange={(event, newValue) => {
        onChange("bins", newValue);
      }}
      getOptionLabel={option => option}
      filterSelectedOptions
      renderInput={params => (
        <TextField
          {...params}
          disabled={!currentFacilityBins.length}
          label="Storage Bins"
        />
      )}
    />
  );
};

export default BinsInput;
