import { gql, useLazyQuery } from "@apollo/client";

const useAssetsByFilter = variables => {
  const [getAssetsByFilter, { data, loading, error }] = useLazyQuery(
    gql`
      query (
        $assetIdArr: [ObjectId]
        $statusArr: [String]
        $customCodeArr: [String]
        $facilityIdArr: [ObjectId]
        $binCodeArr: [String]
        $productIdArr: [ObjectId]
        $categoryIdArr: [ObjectId]
        $offset: Int
        $limit: Int
      ) {
        assetsSearch(
          input: {
            assetIdArr: $assetIdArr
            statusArr: $statusArr
            customCodeArr: $customCodeArr
            facilityIdArr: $facilityIdArr
            binCodeArr: $binCodeArr
            productIdArr: $productIdArr
            categoryIdArr: $categoryIdArr
            offset: $offset
            limit: $limit
          }
        ) {
          _id
          binCode
          customCode
          description
          eventDate
          eventType
          binCode
          status
          facilityId {
            name
            code
          }
          items {
            isPriced
            itemId
            notes
            quantity
            type
          }
        }
      }
    `,
    { variables: variables }
  );
  return { getAssetsByFilter, data, loading, error };
};
export default useAssetsByFilter;
