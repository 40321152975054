import React, { useContext, useEffect } from "react";
import { Context } from "../../context/AppContext";

const TitleWrapper = ({
  children,
  title = "Dashboard",
  titleOnUnmounted = "Dashboard",
}) => {
  const { setHeaderTitle } = useContext(Context);

  useEffect(() => {
    setHeaderTitle(title);
    return () => {
      setHeaderTitle(titleOnUnmounted);
    };
  }, [setHeaderTitle, title, titleOnUnmounted]);

  return <>{children}</>;
};

export default TitleWrapper;
