import { gql, useQuery } from "@apollo/client";

const useFacilities = () => {
	const { data, loading, error } = useQuery(
		gql`
    query GetFacilities {
      facilities {
        _id
        type
        name
        code
        description
        bins {
          code
        }
        city
        state
      }
    }
		`
	);
	return { data, loading, error };
};
export default useFacilities;