import { gql, useQuery } from "@apollo/client";

const useAssetsTransferTimes = () => {
  const { data, loading, error } = useQuery(
    gql`
      query {
        assetsTransferTimes {
          assetId
          facilityId
          status
          tagCode
          customCode
          transferStart
          transferTime
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );
  return { data, loading, error };
};
export default useAssetsTransferTimes;
