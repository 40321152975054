import React, { createContext, useState } from "react";

export const Context = createContext({});

const AppContext = ({ children }) => {
  const [headerTitle, setHeaderTitle] = useState("Dashboard");

  return (
    <Context.Provider value={{ headerTitle, setHeaderTitle }}>
      {children}
    </Context.Provider>
  );
};

export default AppContext;
