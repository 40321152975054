import { gql } from "@apollo/client";

export const GET_ASSETS = gql`
  query GetAssets {
    assets(query: { status_in: [STORED, TRANSFER, RECEIVED] }) {
      _id
      tagCode
      status
    }
  }
`;

export const GET_AVAILABLE_ASSETS = gql`
  query GetAvailableAssets {
    assets(query: { status: EMPTY }) {
      _id
      tagCode
      status
    }
  }
`;

export const GET_FACILITIES = gql`
  query GetFacilities {
    facilities {
      _id
      city
      code
      description
      name
      state
      type
      bins {
        code
      }
    }
  }
`;

export const GET_PRODUCTS_CUSTOM_CODES = gql`
  query productSearch($input: String) {
    productSearch(input: $input) {
      _id
      barCode
      customCode
      name
      description
      score
    }
  }
`;

export const UPDATE_ASSET_INFO = gql`
  mutation updateOneAsset(
    $tagCode: String
    $status: AssetStatus
    $facilityId: AssetFacilityIdRelationInput
    $binCode: String
    $eventDate: DateTime
    $eventType: String
    $items: [AssetItemUpdateInput]
  ) {
    updateOneAsset(
      query: { tagCode: $tagCode }
      set: {
        status: $status
        facilityId: $facilityId
        binCode: $binCode
        eventDate: $eventDate
        eventType: $eventType
        items: $items
      }
    ) {
      _id
      binCode
      customCode
      description
      eventDate
      eventType
      facilityId {
        _id
        city
        code
        description
        name
        state
        type
      }
      lastModifiedBy
      name
      rfidCode
      status
      tagCode
      type
      items {
        isPriced
        itemId
        notes
        quantity
        type
      }
    }
  }
`;

export const GET_ASSETS_DETAILS = gql`
  query AssetDetails($productIdArr: [ObjectId]) {
    products(query: { _id_in: $productIdArr }) {
      _id
      customCode
      name
      description
      tagCode
      categoryId {
        name
      }
      subcategory
    }
  }
`;
