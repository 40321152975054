import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import LoginScreen from "./components/LoginScreen";
import RealmApolloProvider from "./graphql/RealmApolloProvider";
import { useRealmApp, RealmAppProvider } from "./RealmApp";
import AssetsStadisticsPage from "./components/Dashboard/AssetsStadisticsPage";
import NewAssetForm from "./components/Dashboard/NewAssetForm/NewAssetForm";
import Dashboard from "./components/Dashboard/DashboardMain";
import AssetsTableFilter from "./components/AssetsTableFilter";
import NewCategoryForm from "./components/NewCategoryForm";
import NewProductForm from "./components/NewProductForm";
import AppContext from "./context/AppContext";
import TagsHistory from "./components/TagsHistory";

export const APP_ID = process.env.REACT_APP_REALM_APP_ID;

const RequireLoggedInUser = ({ children }) => {
  const app = useRealmApp();
  return app.currentUser ? children : <LoginScreen />;
};

export default function App() {
  return (
    <AppContext>
      <RealmAppProvider appId={APP_ID}>
        <RequireLoggedInUser>
          <RealmApolloProvider>
            <BrowserRouter>
              <Dashboard>
                <Switch>
                  <Route exact path="/" component={AssetsStadisticsPage} />
                  <Route exact path="/new-asset" component={NewAssetForm} />
                  <Route
                    exact
                    path="/search-asset"
                    component={AssetsTableFilter}
                  />
                  <Route exact path="/tags-history" component={TagsHistory} />
                  <Route
                    exact
                    path="/new-category"
                    component={NewCategoryForm}
                  />
                  <Route exact path="/new-product" component={NewProductForm} />
                </Switch>
              </Dashboard>
            </BrowserRouter>
          </RealmApolloProvider>
        </RequireLoggedInUser>
      </RealmAppProvider>
    </AppContext>
  );
}
