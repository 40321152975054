import React, { useEffect, useState } from "react";
import AssetsByFacilityStatus from "./AssetsByFacilityStatus";
import AssetsTransferTimes from "./AssetsTransferTimes";
import ProductsCountsByCategory from "./ProductsCountsByCategory";
import EmptyBins from "./EmptyBins";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import useFacilities from "../../graphql/useFacilities";
import Loading from "../Loading";

const AssetsStadisticsPage = () => {
  const [facilities, setFacilities] = useState();
  const { data: facilitiesData, loading } = useFacilities();

  useEffect(() => {
    if (facilitiesData) {
      let f = {};
      facilitiesData["facilities"].forEach(e => {
        f[e._id] = e.name;
        f[e.code] = e._id;
      });
      setFacilities(f);
    }
  }, [facilitiesData]);

  return loading || facilities === undefined ? (
    <Loading />
  ) : (
    <Grid container spacing={1}>
      {/* Chart */}
      <Grid item xs={12} md={6} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 400,
          }}
        >
          <ProductsCountsByCategory />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 400,
          }}
        >
          <AssetsTransferTimes facilities={facilities} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 400,
          }}
        >
          <AssetsByFacilityStatus />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 400,
          }}
        >
          <EmptyBins />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AssetsStadisticsPage;
