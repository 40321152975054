import { gql, useQuery } from "@apollo/client";

const useCategories = () => {
  const { data, loading, error } = useQuery(
    gql`
      {
        categories {
          _id
          name
        }
      }
    `,
    { fetchPolicy: "network-only" }
  );
  return { data, loading, error };
};

export default useCategories;
