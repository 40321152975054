import React from "react";
import * as Realm from "realm-web";
import { useRealmApp } from "../RealmApp";
import validator from "validator";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Alert, CircularProgress } from "@mui/material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://smartedgetech.io/">
        Smartedge Technologies
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function LoginScreen() {
  const app = useRealmApp();
  // Toggle between logging users in and registering new users
  const [mode, setMode] = React.useState("login");
  const toggleMode = () => {
    setMode(oldMode => (oldMode === "login" ? "register" : "login"));
  };
  // Keep track of form input state
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  // Keep track of input validation/errors
  const [error, setError] = React.useState({});
  // Whenever the mode changes, clear the form inputs
  React.useEffect(() => {
    setError({});
  }, [mode]);

  const [loading, setLoading] = React.useState(false);

  const handleLogin = async () => {
    setLoading(true);
    setError(e => ({ ...e, password: null }));
    try {
      // TODO: Call the logIn() method and pass it the emailPassword credentials.
      await app.logIn(Realm.Credentials.emailPassword(email, password));
      setLoading(false);
    } catch (err) {
      handleAuthenticationError(err, setError);
      setLoading(false);
    }
  };

  const handleRegistrationAndLogin = async () => {
    const isValidEmailAddress = validator.isEmail(email);
    setError(e => ({ ...e, password: null }));
    if (isValidEmailAddress) {
      setLoading(true);
      try {
        // Register the user and, if successful, log them in
        // TODO: Create new emailPassword credentials by calling the registerUser() method.
        await app.emailPasswordAuth.registerUser(email, password);
        setLoading(false);
        return await handleLogin();
      } catch (err) {
        setLoading(false);
        handleAuthenticationError(err, setError);
      }
    } else {
      setError(err => ({ ...err, email: "Email is invalid." }));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url('/img/login-image.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundColor: t =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "calc(100vh - 64px)",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {mode === "login" ? "Log In" : "Register an Account"}
            </Typography>
            {mode === "login" && (error.email || error.password) && (
              <Alert severity="error" style={{ marginTop: "1rem" }}>
                Incorrect user or password
              </Alert>
            )}
            <Box
              component="form"
              noValidate
              onSubmit={e => {
                e.preventDefault();
                mode === "login" ? handleLogin() : handleRegistrationAndLogin();
              }}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={mode !== "login" && error.email ? true : false}
                helperText={mode !== "login" ? error.email : null}
                onChange={e => {
                  setError(e => ({ ...e, email: null }));
                  setEmail(e.target.value);
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={mode !== "login" && error.password ? true : false}
                helperText={mode !== "login" ? error.password : null}
                onChange={e => {
                  setPassword(e.target.value);
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ? (
                  <CircularProgress size={25} color="inherit" />
                ) : mode === "login" ? (
                  "Log In"
                ) : (
                  "Register"
                )}
              </Button>
              <Grid container>
                <Grid item>
                  <Typography component="p" variant="body2">
                    {mode === "login"
                      ? "Don't have an account? "
                      : "Already have an account? "}
                    <Link
                      onClick={e => {
                        e.preventDefault();
                        toggleMode();
                      }}
                      variant="body2"
                      sx={{ cursor: "pointer" }}
                    >
                      {mode === "login"
                        ? "Register one now."
                        : "Log in instead."}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

function handleAuthenticationError(err, setError) {
  const { status, message } = parseAuthenticationError(err);
  const errorType = message || status;
  switch (errorType) {
    case "invalid username":
      setError(prevErr => ({ ...prevErr, email: "Invalid email address." }));
      break;
    case "invalid username/password":
    case "invalid password":
    case "401":
      setError(err => ({ ...err, password: "Incorrect password." }));
      break;
    case "name already in use":
    case "409":
      setError(err => ({ ...err, email: "Email is already registered." }));
      break;
    case "password must be between 6 and 128 characters":
    case "400":
      setError(err => ({
        ...err,
        password: "Password must be between 6 and 128 characters.",
      }));
      break;
    default:
      break;
  }
}

function parseAuthenticationError(err) {
  const parts = err.message.split(":");
  const reason = parts[parts.length - 1].trimStart();
  if (!reason) return { status: "", message: "" };
  const reasonRegex = /(?<message>.+)\s\(status (?<status>[0-9][0-9][0-9])/;
  const match = reason.match(reasonRegex);
  const { status, message } = match?.groups ?? {};
  return { status, message };
}
